@import '../../../index.scss';
.card-background {
  border: none !important;
  border-radius: 10px;
  padding: 15px 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255,146,146,1);
-moz-box-shadow: 0px 0px 5px 0px rgba(255,146,146,1);
box-shadow: 0px 0px 5px 0px rgba(255,146,146,1);
background-color: white !important;
}
textarea {
  resize: none;
  height: 50px;
}
form .form-control:focus{
  border-color: #ced4da;
  box-shadow: none;
}
.rotate-btn {
  transform: rotate(47deg) !important;
  margin-left: -20px;
}
.rotate-btn:focus,
.rotate-btn:hover {
  background-color: inherit;
  border-color: transparent;
}
