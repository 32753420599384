.hover-highlight:hover {
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.status-box {
  box-sizing: border-box;
  padding-inline: 4px;
  border-radius: 3px;
  block-size: min-content;
  text-transform: uppercase;
  font-weight: 600;
}

.status-merged {
  @extend .status-box;
  color: #006644;
  background-color: #e3fcef;
}

.status-opened {
  @extend .status-box;
  color: #0b6ede;
  background-color: #e6f0ff;
}

.status-closed {
  @extend .status-box;
  color: #de8a0b;
  background-color: #fff7e6;
}

.branch-badge {
  @extend .status-box;
  color: #5e6c84;
  text-transform: none;
}

.source-branch {
  @extend .branch-badge;
  background-color: #f4f5f7;
}

.source-branch:hover {
  background-color: #cdcdcd;
  text-decoration: underline;
}

.target-branch {
  @extend .branch-badge;
  background-color: transparent;
}

.clickable-link {
  width: fit-content;
}

.clickable-link:hover {
  text-decoration: underline;
  color: #e75256;
}
