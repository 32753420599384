.hover-highlight:hover {
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.file-count {
  box-sizing: border-box;
  padding-inline: 4px;
  border-radius: 3px;
  block-size: min-content;
  text-transform: uppercase;
  font-weight: 600;
}

.file-added {
  @extend .file-count;
  color: #006644;
  background-color: #e3fcef;
}

.file-removed {
  @extend .file-count;
  color: #de350b;
  background-color: #ffebe6;
}

.file-modified {
  @extend .file-count;
  color: #de8a0b;
  background-color: #fff7e6;
}

.clickable-link:hover {
  text-decoration: underline;
  color: #e75256;
}
