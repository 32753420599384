@import '../../../styling/theming/variables.scss';

.react-datepicker__day--selected,
.react-datepicker__header,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: $primary !important;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: white !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  color: white !important;
  background-color: $primary !important;
}

.react-datepicker__navigation-icon::before {
  border-color: white !important;
}

.date-clear-button {
  border: none;
  background: transparent;
  position: absolute;
  right: 5px;
  top: -30px;
  color: #e7525f;
}
