.selectFieldCustomWrapper {
  .selectFieldCustom__control--is-focused {
    border-color: #f3a9ab !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(231, 82, 86, 0.25) !important;
  }
  .selectFieldCustom__value-container {
    max-height: 70px;
    overflow-y: auto;
  }
}
