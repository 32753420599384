.loader {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  z-index: 9999;
}

.overlay {
  background: #212529;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 9990;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.4;
}
