.achievement-container {
  .wall-card {
    border-radius: 10px;
    padding: 1.3rem;
  }

  .image-wrap {
    max-width: 70px !important;
    border-radius: 50%;
    height: 37px !important;
  }

  .content-container {
    margin-left: 3.3rem !important;
  }

  .card-header {
    border: none !important;
    background-color: #fff;
  }

  .card-inner-body {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
  }

  .card-footer {
    border: none !important;
    background-color: #fff !important;
  }

  .comments-container {
    background-color: #f6f6f6;
    border: none !important;
    border-radius: 10px;
    overflow: hidden;
  }

  .wall-name-tag {
    border-radius: 20px;
    padding: 8px 20px;
    font-size: small;
    background-color: #fae5e5;
    color: #e75256;
  }

  .user-image{
    width: 38px;
    height: 38px;
  }
}
