.overlapped-avatar {
  margin-left: -10px;
}

.avatar-item {
  border: 1px solid #cdcdcd;
  border-radius: 50%;
  box-sizing: border-box;
}

.avatar-item:hover {
  border: 1px solid black;
}
