@import '../../../styling/theming/variables.scss';

.comment-wrap {
  .card-background {
    border: none !important;
    border-radius: 10px;
    padding: 15px 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 146, 146, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(255, 146, 146, 1);
    box-shadow: 0px 0px 5px 0px rgba(255, 146, 146, 1);

    form .form-control:focus {
      border-color: #ced4da;
      box-shadow: none;
    }
  }
  // font-size: 15px;
  .radius-card {
    border-radius: 10px !important;
    background-color: #f6f6f6;

    .msg {
      white-space: pre-line;
      .comment-text {
        margin-bottom: 0 !important;
      }
    }
  }

  .child-wrap:not(.add-comment) {
    // margin-left: 30px;
  }

  .list {
    list-style-type: none !important;
    margin-bottom: 0 !important;
  }
  .comment-area {
    border-radius: 25px;
  }
  .no-bg {
    background-color: transparent !important;
  }
  .content-container {
    margin-left: 3.4rem !important;
  }
  textarea {
    resize: vertical;
    height: 50px;
  }
  .rotate-btn {
    transform: rotate(47deg) !important;
    margin-left: -20px;
  }
  .add-comment {
    margin-bottom: 15px;
    .input-text-area {
      width: 100%;
    }
  }
  .div-rply-icon {
    rotate: 180deg;
    display: inline-block;
    color: $primary;
    // font-size: 1.2rem !important;
  }
  .dots-clr {
    color: #515151;
  }
  .user-image{
    width: 38px;
    height: 38px;
  }
}
