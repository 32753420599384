.hover-highlight:hover {
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.clickable-link:hover {
  text-decoration: underline;
  color: #e75256;
}

.copy-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.text-badge {
  box-sizing: border-box;
  padding-inline: 4px;
  border-radius: 3px;
  block-size: min-content;
  text-transform: uppercase;
  font-weight: 600;
}

.pr-status {
  @extend .text-badge;
  color: #454545;
  background-color: #dedede;
}

.text-line-through {
  text-decoration: line-through;
}
