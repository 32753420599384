$primary: #e75256;
$sub-text: #606060;
$textColor: #000 !important;
$bg-colors: #f3e9e9 !important;
$bg-sec-colors: #f6f6f6 !important;
$bg-tag-color: #FAE5E5 !important;
// $secondary: yellow;

$theme-colors: (
  'primary': $primary
);
