.project-container {
  .wall-card {
    border-radius: 10px;
    padding: 1.3rem;
  }

  .image-wrap {
    max-width: 70px !important;
    border-radius: 50%;
    height: 37px !important;
  }

  .content-container {
    margin-left: 3.3rem !important;
  }

  .card-header {
    border: none !important;
    background-color: #fff;
  }

  .card-section {
    // background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
  }

  .attchment-img {
    max-height: 11rem;
  }

  .assign-feedback {
    border: 1px solid #c7c1c1;
    border-radius: 10px;
  }

  .card-inner-body {
    .conatiner-body {
      padding: 10px;
      // border: 1px solid #c7c1c1;
      border-radius: 10px;
    }
    .grid-assignees {
      padding: 10px;
      border: 1px solid #c7c1c1;
      border-radius: 10px;
    }
    .assignee-img {
      max-width: 70px !important;
      border-radius: 50%;
      height: 37px !important;
    }
    .assignee-data {
      display: flex;
    }
    .assignee-status {
      border-radius: 20px;
      padding: 10px 25px;
      font-size: small;
    }
    .assignee-text {
      color: #585858;
    }
    .assign-feedback {
      border: 1px solid #c7c1c1;
      border-radius: 10px;
    }

    .feedback-section {
      .feedback-row:not(:first-child) {
        border-radius: 0 !important;
        border-top: 1px solid #cecece;
      }
    }
    .submit-btn {
      margin-top: 2rem;
    }
    .feedback-edit {
      float: right;
    }
  }

  .card-footer {
    border: none !important;
    background-color: #fff !important;
  }
  .feed-btn {
    padding: 0.4rem 0.4rem !important;
    margin-top: 30px !important;
  }

  .comments-container {
    background-color: #f6f6f6;
    border: none !important;
    border-radius: 10px;
    overflow: hidden;
  }

  .wall-name-tag {
    border-radius: 20px;
    padding: 8px 20px;
    font-size: small;
    background-color: #fae5e5;
    color: #e75256;
  }

  .user-image{
    width: 38px;
    height: 38px;
  }
}
