.cursor-pointer {
  cursor: pointer;
}

// fonts
.fw-500 {
  font-weight: 500;
}

.box-shadow-none:focus {
  box-shadow: none;
}
