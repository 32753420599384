.git-link:hover {
    text-decoration: underline;
  }
  
  .hover-highlight:hover {
    background-color: #f6f6f6;
    border-radius: 0.25rem;
  }
  
  .pr-details-table {
    font-size: 14px;
  }
  
  .scroll-pr-content {
    height: 45vh;
    overflow-y: auto;
  }
  