@import '../../styling/theming/variables.scss';

.wall-post-form-container {
  .selectFieldCustom__control,
  input,
  textarea {
    border-radius: 10px;
  }
  .wall-post-form-wrapper {
    .welcome-card {
      // padding: 2rem 2rem 1rem 2rem;
      // border-radius: 10px;
      // background-color: $bg-sec-colors;
      .description {
        height: 5rem !important;
      }
      .tag-people-col {
        max-height: 70px;
        overflow-y: auto;
        max-width: 80%;
        font-size: 14px;
        align-self: center;
      }
      .tp-head-col {
        width: fit-content;
      }
      .tagged-badge {
        background-color: $bg-tag-color;
        color: $primary;
        border-radius: 15px;
        // border: 1px solid $primary;
        font-weight: 400;
        padding: 8px 15px 8px 15px !important;
        font-size: 0.9rem !important;
        margin-bottom: 0.25rem;
        .tagged-close {
          padding: 0 0 0 5px !important;
          font-size: smaller;
          vertical-align: inherit;
          .tag-cls-btn {
            color: $textColor;
            background-color: $bg-tag-color;
            box-shadow: none;
          }
        }
        .tagged-close:hover,
        .tagged-close:focus {
          background-color: $bg-tag-color;
          border-color: $bg-tag-color;
        }
      }
    }
  }
}
