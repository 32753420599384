.task-container {
  .wall-wrapper {
    .wall-card {
      border-radius: 10px !important;
      padding: 1.3rem;

      .card-header {
        border: none !important;
        background-color: #fff;
        .image-wrap {
          max-width: 70px !important;
          border-radius: 50%;
          height: 37px !important;
        }
      }
      .content-container {
        margin-left: 3.3rem !important;
      }
    }

    .card-inner-body {
      .card-section {
        // background-color: #f6f6f6;
        // border: 1px solid #c7c1c1;
        border-radius: 10px;
        padding: 15px;
      }
      .vertical-line {
        border-right: 2px solid #cecece;
      }

      .feedback-section {
        .feedback-row:not(:first-child) {
          border-radius: 0 !important;
          // border-top: 1px solid #cecece;
        }
      }
      .feedback-edit {
        float: right;
      }
      .assign-feedback {
        border: 1px solid #c7c1c1;
        border-radius: 10px;
      }
      .grid-main {
        .grid-assignees {
          padding: 10px;
          border: 1px solid #c7c1c1;
          border-radius: 10px;
        }
        .assignee-img {
          max-width: 70px !important;
          border-radius: 50%;
          height: 37px !important;
        }
        .assignee-data {
          display: flex;
        }
        .assignee-status {
          border-radius: 20px;
          padding: 10px 25px;
          font-size: small;
        }
        .assignee-text {
          color: #585858;
        }
      }
    }
    .submit-btn {
      margin-top: 2rem;
    }
    .card-footer {
      border: none !important;
      background-color: #fff !important;
    }

    .comments-container {
      background-color: #f6f6f6;
      border: none !important;
      border-radius: 10px;
    }

    .wall-name-tag {
      border-radius: 20px;
      padding: 8px 20px;
      font-size: small;
      background-color: #fae5e5;
      color: #e75256;
    }
  }
  .user-image {
    width: 38px;
    height: 38px;
  }
}
