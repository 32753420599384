$primaryColor: #e75256;
.gitLabConnectButton {
  display: flex;
  align-items: center;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  font-size: 12px;
  background-color: #fff;
  padding: 4px 8px !important;
  border-radius: 4px !important;

  &:hover {
    color: #fff !important;
    background: $primaryColor;
  }
}

.createBranchButton {
  display: flex;
  align-items: center;
  border: 0px;
  background-color: #fff;
  padding: 6px 10px !important;
  border-radius: 4px !important;
  margin-bottom: 8px;
  width: 100%;
  &:hover {
    background-color: #f6f6f6 !important;
  }
}
.gitlabLogo {
  width: 25px;
  height: 25px;
  padding-right: 8px;
}
