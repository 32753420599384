.dev-details {
  overflow: hidden;
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.dev-details:hover {
  background-color: #efefef;
}

.add-branch-btn {
  max-width: 0;
  transition: max-width 0.22s ease-in-out;
  overflow: hidden;
  border: none;
  background: transparent;
  margin: 0 0.4rem 0 0;
  padding: 0;
}

.dev-details:hover .add-branch-btn {
  max-width: 1rem;
}

.branch-link:hover {
  text-decoration: underline;
}

.branch-popover {
  min-width: 275px;
}

.add-branch-popover {
  min-width: 350px;
}
