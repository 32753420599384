.copy-input {
  border: none;
}

.copy-input:focus {
  box-shadow: none;
  border: none;
}

.copy-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}
