@import '../../../styling/theming/variables.scss';

.file-upload-container {
  //   .row {
    .image-preview {
      object-fit: cover;
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    .attach-img-container{
      border: 1px solid rgb(212, 212, 212);
      padding: 8px;
      border-radius: 10px;
    }
  .preview-block {
    // display: inline;
    max-width: 200px;
    max-height: 200px;
    .image-container {
      // height: 150px;
      // width: 150px;
      max-width: 300px;
      max-height: 300px;
      position: relative;
      border: 1px solid $bg-colors;
      padding: 10px;
      #image-preview {
        object-fit: cover;
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }
    }
  }
  //   }
}
