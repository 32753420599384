@import './index.scss';

.footer {
  margin-top: 1rem;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff6f6;
  z-index: 0;
  position: fixed;
}

main {
  margin-bottom: 60px;
}

.bg-colors {
  background-color: $bg-colors;
}

.col-lg-2 {
  width: 14.66667% !important;
  padding-left: 0px !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.set-sidebar {
  margin-left: 14.96667% !important;
  transition: 0.2s;
  margin-top: 6rem !important;
  padding: 1.5rem 0.5rem !important;
}

.text-sub {
  color: $sub-text;
}

@media only screen and (max-width: 1024px) {
  .set-sidebar {
    margin-left: 16.96667% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .time-text {
    font-size: small !important;
  }
}
@media only screen and (max-width: 1024px) {
  .hide-text {
    display: none;
  }
}
