@import '../../../styling/theming/variables.scss';

.tagged-people-wrapper {
  display: flex;
  .tag-icon {
    align-self: center;
    color: $primary;
  }
  .taggedPeople-select__control {
    border-radius: 10px;
    border-color: $bg-sec-colors;
    background-color: $bg-sec-colors;
  }
  .taggedPeople-select__placeholder {
    color: Black;
  }
  .taggedPeople-select__menu {
    width: fit-content;
  }
  .taggedPeople-select__value-container {
    background-color: $bg-sec-colors;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
  }
  .taggedPeople-select__control--is-focused {
    border-color: #f3a9ab !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(231, 82, 86, 0.25) !important;
  }
}
