@import '../../../styling/theming/variables.scss';

.comment-container {
  .cmt-btn {
    background: none !important;
    border: none !important;
    i {
      line-height: 0;
    }
  }
}
.div-rply-icon {
  rotate: 180deg;
  display: inline-block;
  color: $primary;
  // font-size: 1.2rem !important;
}
