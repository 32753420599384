.like-container {
  .like-btn {
    background: none !important;
    border: none !important;
    i {
      line-height: 0;
    }
  }
  .icon{
    font-size: 1.2rem !important;
  }

  .bump {
    animation: bump 400ms ease-out;
  }
  
  @keyframes bump {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(0.9);
    }
    30% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
}
