.git-link:hover {
  text-decoration: underline;
}

.hover-highlight:hover {
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.branch-details-table {
  font-size: 14px;
}

.scroll-branch-content {
  height: 45vh;
  overflow-y: auto;
}
