$primaryColor: #e75256;

.task-card {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 8px;
  cursor: grab;
  position: relative;
  outline: 2px inset transparent;

  &:hover {
    outline: 2px inset;
  }

  &--dragging {
    opacity: 0.3;
    background-color: #ffffff;
    border: 2px solid $primaryColor;
    padding: 0.625rem;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: grab;
    position: relative;
  }

  &--edit {
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    border-radius: 8px;
    cursor: grab;
    position: relative;
  }

  &__textarea {
    // width: 100%;
    resize: none;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    max-height: 50px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 8px;

    &--left {
      display: flex;
      align-items: center;
    }
  }

  &__priority {
    height: 20px;
  }

  &__user-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    // margin-right: 8px;
  }

  &__key {
    font-weight: bold;
    color: #7a869a;
    font-size: 14px;
  }

  &__content {
    // margin: 0;
    // height: auto;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: auto;
    white-space: pre-wrap;
    width: 90%;
    max-height: 50px;
  }

  &__delete-button {
    z-index: 100;
    stroke: #fff;
    // position: absolute;
    // right: 10px;
    // top: 30px;
    // transform: translateY(-50%);
    // padding: 4px;
    border-radius: 4px;
    opacity: 0.6;
    border: none;
    background-color: transparent;
    border: 1px solid transparent;
    height: 35px;
    width: 35px;

    &:hover {
      opacity: 1;
      stroke: #fff;
      border-radius: 4px;
      border: 1px solid $primaryColor;
    }
  }
}
