@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&family=Poppins:wght@100;200;400;500;600&display=swap');
@import './styling/theming/Custom.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import './styling/utilities.scss';
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-circular-progressbar/dist/styles.css';

body {
  background-color: #ffffff !important;
  font-family: 'Open Sans', sans-serif;
}
body::-webkit-scrollbar {
  // display: none;
  width: 8px;
}

.btn-primary,
.btn-outline-primary {
  // padding: 10px 25px !important;
  padding: 8px 20px !important;
  border-radius: 0.4rem !important;
}

.btn-primary {
  color: white !important;
}

.btn-outline-primary:hover {
  color: white !important;
}

.text-area-field {
  resize: vertical;
  border-radius: 10px;
}
.input-field-error {
  color: red;
}
// Card styles

.card-over {
  padding: 15px 30px;
  border-radius: 0.7rem !important;
  margin-top: 2% !important;
  background-color: snow !important;
}

// heading text styling

.header-text-style {
  margin: none !important;
  font-size: 1.6rem !important;
}

.header-underline {
  display: block;
  background-color: $primary;
  padding: 2px;
  max-width: 50px;
  margin-top: 7px;
}
.back-btn {
  width: auto !important;
  padding: 5px 12px !important;
}

.vertical-line {
  border-right: 2px solid #cecece;
}

.description-content {
  white-space: pre-line;
}

.single-line {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.card-text-content {
  font-size: large;
}
.no-bg {
  background-color: transparent !important;
}

.font-bold {
  font-weight: 600 !important;
}

.font-semi-bold {
  font-weight: 500 !important;
}

.font-regular {
  font-weight: 400 !important;
}

.mt-edit-btn {
 margin-top: 2rem;
}

.attach-img-container{
  border: 1px solid rgb(212, 212, 212);
  padding: 8px;
  border-radius: 10px;
  min-width: 12rem;
}

.img-attachment{
  height: 3.5rem;
}

.text-char-limit-25{
  white-space: nowrap ;
    overflow: hidden ;
    text-overflow: ellipsis ;
    max-width: 25ch ;
}

.text-char-limit-15{
  white-space: nowrap ;
    overflow: hidden ;
    text-overflow: ellipsis ;
    max-width: 15ch ;
}

.scrollableField{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3; 
  -webkit-box-orient: vertical;
}