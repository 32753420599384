.meeting-minutes-container {
  .wall-wrapper {
    .wall-card {
      border-radius: 10px !important;
      padding: 1.3rem;

      .card-header {
        border: none !important;
        background-color: #fff;
        .image-wrap {
          max-width: 70px !important;
          border-radius: 50%;
          height: 37px !important;
        }
      }

      .content-container {
        margin-left: 3.3rem !important;
      }
    }

    .card-inner-body {
      border: 1px solid #c7c1c1;
      // background-color: #f6f6f6;
      border-radius: 10px;
      padding: 15px;

      .vertical-line {
        border-right: 2px solid #cecece;
      }

    }

    .card-footer {
      border: none !important;
      background-color: #fff !important;
    }

    .comments-container {
      background-color: #f6f6f6;
      border: none !important;
      border-radius: 10px;
      overflow: hidden;
    }

    .wall-name-tag {
      border-radius: 20px;
      padding: 8px 20px;
      font-size: small;
      background-color: #FAE5E5;
      color: #e75256;;
    }
  }

  .user-image{
    width: 38px;
    height: 38px;
  }
}
