.notfound .notfound-404 {
    position: relative;
    height: 240px;
    z-index: 1000;
  }
  
  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .notfound {
    /* max-width: 520px; */
    width: 100%;
    line-height: 1.4;
    text-align: center;
    /* padding-top: 25vh; */
  }
  
  .notfound .notfound-404 h3 {
    font-family: 'Cabin', sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    // text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    /* color: #262626; */
    color: #e75256;
    // text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
  }
  
  .notfound .notfound-404 h1 > span {
    text-shadow: -8px 0px 0px #fff;
  }
  
  .notfound h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  
  .notfound a {
    font-family: 'Raleway', sans-serif;
    display: inline-block;
    font-weight: 700;
    border-radius: 15px;
    text-decoration: none;
    color: #39b1cb;
  }
  
  .notfound a > .arrow {
    position: relative;
    top: -2px;
    border: solid #39b1cb;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  