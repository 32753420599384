.custom-modal-style {
  // max-height: 680px;
  padding: 15px;
  z-index: 100;
}
.modal-title {
  color: #e75256 !important;
  width: 100% !important;
  margin-right: 10px;
}
